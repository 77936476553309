import { Usdx, Drow, CakeTwitter, CakeTelegram, CakeDiscord, Twitter, Telegram, Discord, Close } from '../../components/Svg'
import { useState, useEffect, useCallback, useMemo } from 'react'
import './index.scss'
import { getBaseInfo } from '../../api'
import { rate, numFormat } from '../../utils/format'
// import styled from "@emotion/styled"

export default function Home() {
  const [isOpenMenu, setIsOpenMenu] = useState(false) 
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  const [isShowTip, setIsShowTip] = useState(false)
  const [menuBg, setMenuBg] = useState('rgba(0, 0, 0, 1)')
  const [isFilter, setIsFilter] = useState('blur(0px)')
  const [infoDate, setInfoDate] = useState<{tvl: number, users: number, yeild: number}>({
    tvl: 0,
    users: 0,
    yeild: 0,
  })

  const cssVars = useMemo(() => {
    let res: any = { '--background': menuBg, '--isfilter': isFilter }
    return res
  }, [menuBg, isFilter])

  const initData = async () => {
    try {
      const res = await getBaseInfo()
      if (res && res.result) {
        setInfoDate(res.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const init = useCallback(async () => {
    await initData()
  }, [])

  const pageScroll = () => {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || 0
    if (scrollTop / 1000 < 0.1) {
      setIsFilter(`blur(0px)`)
      setMenuBg(`rgba(0, 0, 0, 1)`)
    } else {
      setIsFilter(`blur(10px)`)
      setMenuBg(`rgba(0, 0, 0, ${scrollTop / 1000 > 0.5 ? 0.5 : scrollTop / 1000})`)
    }
  }

  const initState = () => {
    setIsOpenMenu(false)
    setIsOpenSubMenu(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => pageScroll())
    window.addEventListener('resize', () => initState())
    return () => {
      window.removeEventListener('scroll', () => pageScroll())
      window.removeEventListener('resize', () => initState())
    }
  }, [])

  useEffect(() => {
    init()
    const timeout = setInterval(() => {
      init()
    }, 60000)
    return () => {
      clearInterval(timeout)
    }
  }, [init])

  const setMainScroll = () => {
    if (isOpenMenu) {
      document.body.className = 'no-scroll'
    } else {
      document.body.className = ''
    }
  }

  useEffect(() => {
    setMainScroll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMenu])
  return (
    <div className="usdx-home-page">
      <div className="header-box" style={cssVars}>
        <div className="header-l">
          <div className="logo">
            <Usdx />
          </div>
          <div className="nav-box">
            <a className="nav-item ff-dots" href="https://app.usdx.money/airdrop" target="_blank" rel="noopener noreferrer">Airdrop</a>
            <div className="nav-item ff-dots nav-item-community">
              Community
              <div className="icon-wapper-box">
                <div className="sub-nav-bg">
                  <a href="https://t.me/USDX_Community" target="_blank" className="icon-item" rel="noopener noreferrer">
                    <div className="icon-box flex jc-center ai-center"><CakeTelegram /></div>
                    <div className="title ff-dots">Telegram</div>
                  </a>
                  <a href="https://twitter.com/usdx_money" target="_blank" className="icon-item" rel="noopener noreferrer">
                    <div className="icon-box flex jc-center ai-center"><CakeTwitter /></div>
                    <div className="title ff-dots">Twitter</div>
                  </a>
                  <a href="https://discord.com/invite/usdxmoney" target="_blank" className="icon-item" rel="noopener noreferrer">
                    <div className="icon-box flex jc-center ai-center"><CakeDiscord /></div>
                    <div className="title ff-dots">Discord</div>
                  </a>
                </div>
              </div>
            </div>
            <a className="nav-item ff-dots" href="https://docs.usdx.money/" target="_blank" rel="noopener noreferrer">Docs</a>
          </div>
        </div>
        <div className="flex ai-center">
          <a href="https://app.usdx.money/" target="_blank" className="to-app cursor-select" rel="noopener noreferrer">Enter App</a>
          <div className={isOpenMenu ? 'open menu' : 'menu'} onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <div className="menu-button-line top"></div>
            <div className="menu-button-line middle"></div>
            <div className="menu-button-line last"></div>
          </div>
        </div>
      </div>
      <div className="content-box">
        <div className="content-left">
          <p className="title ff-dots stroke-after" data-content="Synthetic USD">Synthetic USD</p>
          <p className="desc ff-500"><span>USDX</span> peg stability is ensured through the use of delta hedging derivatives positions against  protocol-held collateral.</p>
        </div>
        <div className="content-right">
          <div className="tvl-title">
            <div className="tvl-label stroke-after" data-content="TVL">TVL</div>
            <div className="super-box">
              <a href="https://twitter.com/usdx_money" target="_blank" rel="noopener noreferrer"><Twitter /></a>
              <a href="https://t.me/USDX_Community" target="_blank" rel="noopener noreferrer"><Telegram /></a>
              <a href="https://discord.com/invite/usdxmoney" target="_blank" rel="noopener noreferrer"><Discord /></a>
            </div>
          </div>
          <div className="tvl-value stroke-after" data-content={`$${ numFormat(infoDate.tvl, 2) }`}>${ numFormat(infoDate.tvl, 2) }</div>
          <div className="item-box">
            <div className="item">
              <div className="label ff-dots stroke-after" data-content="Yield">Yield</div>
              <div className="value ff-dots stroke-after" data-content={rate(infoDate.yeild)}>{ rate(infoDate.yeild) }</div>
            </div>
            <div className="item">
              <div className="label ff-dots stroke-after" data-content="Users">Users</div>
              <div className="value ff-dots stroke-after" data-content={numFormat(infoDate.users, 2)}>{ numFormat(infoDate.users, 2) }</div>
            </div>
          </div>
        </div>
      </div>
      <div className={ !isOpenMenu ? 'mobile-nav show' : 'mobile-nav' } style={{ display: !isOpenMenu ? 'none' : 'block' }}>
        <ul>
          <li><a href="https://app.usdx.money/airdrop" target="_blank" className="menu-item" rel="noopener noreferrer">Airdrop</a></li>
          <li><div className={ isOpenSubMenu ? 'menu-item menu-item-draw menu-item-open' : 'menu-item menu-item-draw' } onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}>Community<Drow /></div></li>
          <div className={ isOpenSubMenu ? "close-li sub-li-open": "close-li" }>
            <li className="sub-li">
              <a href="https://twitter.com/usdx_money" target="_blank" className="menu-item" rel="noopener noreferrer">
                <div className="icon-box flex jc-center ai-center"><CakeTwitter /></div>
                Twitter
              </a>
            </li>
            <li className="sub-li">
              <a href="https://t.me/USDX_Community" target="_blank" className="menu-item" rel="noopener noreferrer">
                <div className="icon-box flex jc-center ai-center"><CakeTelegram /></div>
                Telegram
              </a>
            </li>
            <li className="sub-li">
              <a href="https://discord.com/invite/usdxmoney" target="_blank" className="menu-item" rel="noopener noreferrer">
                <div className="icon-box flex jc-center ai-center"><CakeDiscord /></div>
                Discord
              </a>
            </li>
          </div>
          <li><a href="https://docs.usdx.money/" target="_blank" className="menu-item" rel="noopener noreferrer">Docs</a></li>
        </ul>
      </div>
      <div className="backed-btn" onClick={() => setIsShowTip(true)}></div>
      { isShowTip && (
        <div className="backed-by">
          <div className="tip-img"><div className='close-svg' onClick={() => setIsShowTip(false)}><Close /></div></div>
        </div>
      ) }
    </div>
  )
}
